<template>
  <div class="recruit">
    <TopNavBar />
    <Carousel :carousels="carousels" />
    <div class="box">
      <div class="welfare_box">
        <Title title="福利待遇" />
        <div class="welfares">
          <div v-for="(item, index) in welfares" :key="index" class="item">
            <div>
              <img :src="item.imgUrl" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position_box">
      <Title title="招聘职位" />
      <div class="positions">
        <div class="item" v-for="item in positions" :key="item.id">
          <h3>{{ item.title }}</h3>
          <p>{{ item.salaryRange }}</p>
          <span>工作经验：{{ item.workExperience }}</span>
          <span>学历要求：{{ item.education }}</span>
          <span>招聘人数：{{ item.recruitingNumbers }}</span>
          <div>
            <el-button type="text" @click="toDetails(item)">去应聘 ></el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="environment_box">
        <Title title="办公环境" />
        <div class="environments">
          <img
            @click="num >= 1 ? onMove('left') : null"
            :src="
              require('@/assets/images/recruit/environment/left' +
                (num >= 1 ? '' : '_dis') +
                '.png')
            "
            alt=""
          />
          <div class="img_box">
            <img
              :src="
                require('@/assets/images/recruit/environment/img' +
                  (index + num) +
                  '.svg')
              "
              alt=""
              v-for="(item, index) in 4"
              :key="index"
            />
          </div>
          <img
            @click="num <= 0 ? onMove('right') : null"
            :src="
              require('@/assets/images/recruit/environment/right' +
                (num <= 0 ? '' : '_dis') +
                '.png')
            "
            alt=""
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { recruitList } from '../api/index'
import { welfares } from "@/assets/js/static.js";
export default {
  name: "Recruit",
  data() {
    return {
      welfares,
      positions: null,
      num: 0,
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/6.png"),
        },
      ],
    };
  },
  mounted() {
    this.loadRecruitList()
  },
  methods: {
    async loadRecruitList() {
      const data = await recruitList()
      this.positions = data
    },
    onMove(value) {
      switch (value) {
        case "left":
          this.num--;
          break;
        case "right":
          this.num++;
          break;
      }
    },
    toDetails(item) {
      console.log(item);
      this.$router.push({
        name:"RecruitDetails",
        query: item
      });
    },
  },
};
</script>

<style scoped lang="scss">
.recruit {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .welfare_box {
    margin-top: 80px;
    width: 920px;
    .welfares {
      display: grid;
      grid-template-columns: repeat(3, 33.33%);
      justify-content: center;
      align-content: center;
      .item {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          width: 160px;
          display: flex;
          align-items: center;
          > img {
            width: 40px;
            height: 40px;
          }
          > span {
            display: block;
            margin-left: 15px;
            line-height: 40px;
            font-size: 25px;
            color: #333;
            font-weight: 300;
          }
        }
      }
    }
  }
  .position_box {
    margin-top: 90px;
    padding: 0 270px;
    .positions {
      margin-top: 10px;
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      .item {
        padding: 35px 0 25px;
        width: 300px;
        height: 310px;
        box-shadow: 0px 0px 5px #86baea40;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          font-size: 25px;
          font-weight: 400;
          color: #000;
        }
        p {
          font-size: 25px;
          color: #fb8742;
        }
        span {
          padding: 0 40px;
          font-size: 20px;
          color: #333;
          text-align: left;
          font-weight: 300;
        }
        > div {
          padding: 0 40px;
          text-align: right;
          .el-button {
            font-size: 20px;
            padding: 0;
          }
        }
      }
    }
  }
  .environment_box {
    width: 1413px;
    margin-top: 100px;
    margin-bottom: 100px;
    .environments {
      margin-top: 65px;
      height: 184px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > img {
        width: 60px;
        height: 80px;
        cursor: pointer;
      }
      .img_box {
        width: 100%;
        padding: 0 30px;
        height: 184px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
